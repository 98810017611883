import React from 'react'
import { StyleSheet, Text, View, Image, TouchableOpacity } from 'react-native'
import * as Linking from 'expo-linking'

const products = [
  {
    name: 'Quantum Sudoku',
    icon: require('../assets/QuantumSudoku/icon.jpg'),
    links: [
      {
        icon: 'apple',
        label: 'App Store',
        url: 'https://apps.apple.com/us/app/quantum-sudoku/id1567678705',
      },
      {
        icon: 'google',
        label: 'Google Play',
        url: 'https://play.google.com/store/apps/details?id=com.marketpeeks.sudo3',
      },
      {
        icon: 'youtube',
        label: 'YouTube',
        url: 'https://youtu.be/LaEOR-m0cnE',
      },
    ],
  },

  {
    name: 'Word Revealed',
    icon: require('../assets/WordRevealed/icon.jpg'),
    links: [
      {
        icon: 'apple',
        label: 'App Store',
        url: 'https://apps.apple.com/us/app/word-revealed/id1589611202',
      },
      {
        icon: 'google',
        label: 'Google Play',
        url: 'https://play.google.com/store/apps/details?id=com.marketpeeks.wordreveal',
      },
      {
        icon: 'youtube',
        label: 'YouTube',
        url: 'https://youtu.be/e5dzUYh2_J0',
      },
    ],
  },
  {
    name: 'Theme Crosswords',
    icon: require('../assets/ThemeCrosswords/icon.jpg'),
    links: [
      {
        icon: 'apple',
        label: 'App Store',
        url: 'https://apps.apple.com/us/app/theme-crosswords/id1595875633',
      },
      {
        icon: 'google',
        label: 'Google Play',
        url: 'https://play.google.com/store/apps/details?id=com.marketpeeks.themewords',
      },
      {
        icon: 'youtube',
        label: 'YouTube',
        url: 'https://youtu.be/LIAFqYji9JM',
      },
    ],
  },
  {
    name: 'Stepping Stone Puzzle',
    icon: require('../assets/steppingStone/icon512L.png'),
    links: [
      {
        icon: 'apple',
        label: 'App Store',
        url: 'https://apps.apple.com/us/app/stepping-stone-puzzle/id1607899251',
      },
      {
        icon: 'google',
        label: 'Google Play',
        url: 'https://play.google.com/store/apps/details?id=com.marketpeeks.steppingstones',
      },
    ],
  },

  {
    name: 'Defender 22',
    icon: require('../assets/Defender/tankIcon512.png'),
    links: [
      {
        icon: 'apple',
        label: 'App Store',
        url: 'https://apps.apple.com/us/app/defender-22/id1619625262',
      },
      {
        icon: 'google',
        label: 'Google Play',
        url: 'https://play.google.com/store/apps/details?id=com.marketpeeks.defender',
      },
    ],
  },
]

const Link = (props: any) => {
  const icon = props.link.icon
  return (
    <TouchableOpacity
      onPress={() => {
        Linking.openURL(props.link.url)
      }}
    >
      <View
        style={{
          width: 40,
          backgroundColor: '#F2F2F2',
          margin: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: 2,
          borderRadius: 6,
        }}
      >
        <Image
          source={
            icon == 'apple'
              ? require('../assets/all/apple.png')
              : icon == 'google'
              ? require('../assets/all/google.png')
              : require('../assets/all/youtube.png')
          }
          style={{ height: 20, width: 24 }}
        />
      </View>
    </TouchableOpacity>
  )
}

const ProductThumb = (props: any) => {
  const product = products[props.idx]
  return (
    <View style={styles.productBox} key={props.idx.toString()}>
      <Image source={product.icon} style={styles.icon} />
      <Text style={styles.productName}>{product.name}</Text>
      {product.links.map((x, i) => (
        <View key={i.toString()} style={styles.linkBox}>
          <Link link={x} />
          <Text style={{ marginLeft: 4 }}>{x.label}</Text>
        </View>
      ))}
    </View>
  )
}

const MainSection = (props: any) => {
  return (
    <View style={styles.mainSection}>
      {products.map((x, i) => (
        <ProductThumb key={i.toString()} idx={i} />
      ))}
    </View>
  )
}

export default MainSection

const color1 = '#FFB755'
const color2 = '#4C8F77'
const color3 = '#214035'

const styles = StyleSheet.create({
  mainSection: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
  },
  productBox: {
    width: 170,
    height: 264,
    backgroundColor: color1,
    marginTop: 8,
    marginHorizontal: 4,
    padding: 6,
    borderRadius: 8,
  },
  icon: {
    width: 140,
    height: 140,
    borderRadius: 16,

    marginBottom: 4,
    marginLeft: 8,
  },
  productName: {
    fontSize: 14,
    fontWeight: 'bold',
    color: color3,
    marginBottom: 4,
  },
  linkBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
})
