import { StatusBar } from 'expo-status-bar'
import React from 'react'
import { StyleSheet, Text, View } from 'react-native'
import Header from './src/Header'
import MainSection from './src/MainSection'

export default function App() {
  return (
    <View style={styles.container}>
      <Header />
      <MainSection />
      <Text style={styles.footerText}>{'(c) 2022, CW Innovations LLC'}</Text>
      <StatusBar style='auto' />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  footerText: {
    color: '#53BFBD',
    fontSize: 12,
    marginBottom: 4,
  },
})
