import React from 'react'
import {
  StyleSheet,
  Text,
  View,
  Image,
  ImageBackground,
  useWindowDimensions,
  TouchableOpacity,
  Linking,
} from 'react-native'

const color1 = '#FFB755'
const color2 = '#4C8F77'
const color3 = '#214035'

const Header = (props: any) => {
  const dim = useWindowDimensions()
  return (
    <View style={styles.header}>
      <ImageBackground
        style={{ width: dim.width, height: 90 }}
        source={require('../assets/all/burstWide.png')}
      >
        <View style={styles.actionBar}>
          <TouchableOpacity
            onPress={() => {
              try {
                Linking.openURL('mailto:info@cw-innovations.com')
              } catch (error) {
                console.log
              }
            }}
          >
            <Text style={styles.action}>{'contact us'}</Text>
          </TouchableOpacity>
        </View>
        <Image
          style={styles.image}
          source={require('../assets/all/marketpeeks.png')}
        />
      </ImageBackground>
    </View>
  )
}

const styles = StyleSheet.create({
  actionBar: {
    display: 'flex',
    alignItems: 'flex-end',
    marginTop: 24,
  },
  action: {
    color: color2,
    marginHorizontal: 12,
  },
  header: {},
  image: {
    position: 'absolute',
    top: 10,
    left: 0,
    width: 350,
    height: 90,
  },
})

export default Header
